import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';
import Selectors from 'src/state/root-selectors';

import * as authStyles from 'src/styles/auth.module.scss';

const SomethingWentWrong = () => {
    const env = useSelector(Selectors.config.environment);
    const { widths } = SUI_GRID_CENTERED;
    return (
        <div className={authStyles.authWrapper}>
            <Grid
                container
                centered
                mobile={widths.mobile}
                tablet={widths.tablet}
                computer={widths.computer}
            >
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                        textAlign="center"
                    >
                        <p className="p1">Oops! Something went wrong.</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                        textAlign="center"
                    >
                        <p className="p1">
                            Please make sure to comeback using the{' '}
                            <a href={`https://cx.${env === 'prod' ? '' : 'dev.'}pvolve.com`}>
                                <strong>CX TOOL</strong>
                            </a>
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default SomethingWentWrong;
