import React from 'react';
import { Grid } from 'semantic-ui-react';
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';
import { LinkWithArrow } from '../shared';

import * as authStyles from 'src/styles/auth.module.scss';

const SomethingWentWrong = ({ userEmail }: { userEmail: string }) => {
    const { widths } = SUI_GRID_CENTERED;
    return (
        <div className={authStyles.authWrapper}>
            <Grid
                container
                centered
                mobile={widths.mobile}
                tablet={widths.tablet}
                computer={widths.computer}
            >
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                        textAlign="center"
                    >
                        <p className="p1">
                            You are already logged in as <strong>{userEmail}</strong>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                        textAlign="center"
                    >
                        <LinkWithArrow to="/account" label="Go to Account page" centered />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default SomethingWentWrong;
