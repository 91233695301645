import React from 'react';
import { Grid } from 'semantic-ui-react';
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';

import * as authStyles from 'src/styles/auth.module.scss';

const Unauthorized = () => {
    const { widths } = SUI_GRID_CENTERED;
    return (
        <div className={authStyles.authWrapper}>
            <Grid
                container
                centered
                mobile={widths.mobile}
                tablet={widths.tablet}
                computer={widths.computer}
            >
                <p className="p1">You must login to perform this action.</p>
                <Grid className={authStyles.bottomContent} container centered>
                    <Grid.Row>
                        <Grid.Column
                            mobile={widths.mobile}
                            tablet={widths.tablet}
                            computer={widths.computer}
                            textAlign="center"
                        >
                            {/* Using anchor tag to force handlePageLoad sagas to create a redirect cookie */}
                            <a href={`/continue?redirectUrl=/log-in-as/${window.location.search}`}>
                                <b>Log in</b>
                            </a>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid>
        </div>
    );
};

export default Unauthorized;
