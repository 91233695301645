import React, { useReducer } from 'react';
import Actions from 'src/state/root-actions';
import { useDispatch } from 'react-redux';
import { Grid, Button } from 'semantic-ui-react';
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';
import { LinkWithArrow, Loader } from '../shared';

import * as authStyles from 'src/styles/auth.module.scss';

const initialState = {
    label: '',
    success: false,
    loading: false,
    error: false,
};

const reducer = (
    state: { label: string; success: boolean; loading: boolean; error: boolean },
    action: { type: string; userEmail?: string }
) => {
    switch (action.type) {
        case 'error':
            return {
                ...state,
                label: 'Something went wrong! Please try again.',
                loading: false,
                error: true,
            };
        case 'loadingAdminToken':
            return { ...state, label: 'Loading admin token...', loading: true, error: false };
        case 'loadingLogout':
            return { ...state, label: 'Logging you out...' };
        case 'loadingLogInAs':
            return { ...state, label: `Logging in as ${action.userEmail}...` };
        case 'logInAsSuccess':
            return {
                ...state,
                label: `Login successful`,
                success: true,
                loading: false,
            };
        default:
            return initialState;
    }
};

interface ILoginAsProps {
    user: { id: string; email: string };
    setLoading: (loading: boolean) => void;
}

const LoginAs = ({ user, setLoading }: ILoginAsProps) => {
    const [state, dispatchState] = useReducer(reducer, initialState);
    const dispatch = useDispatch();
    const { widths } = SUI_GRID_CENTERED;

    const handleOnLogInAsClick = () => {
        setLoading(true);
        dispatchState({ type: 'loadingAdminToken' });
        dispatch(
            Actions.web.handleLogInAs.trigger({
                userId: user.id as string,
                onFailure: () => dispatchState({ type: 'error' }),
                onSuccess: (data) => handleOnLogInAsSuccess(data),
            })
        );
    };
    const handleOnLogInAsSuccess = (data: any) => {
        dispatchState({ type: 'loadingLogout' });
        dispatch(
            Actions.auth.logout.trigger({
                onSuccess: () => {
                    setTimeout(() => handleOnLoggedOutSuccess(data), 2000);
                },
            })
        );
    };
    const handleOnLoggedOutSuccess = (data: any) => {
        dispatchState({ type: 'loadingLogInAs', userEmail: user.email });
        dispatch(
            Actions.auth.login.trigger({
                email: '',
                password: '',
                logInAsTokens: data.data,
                onFailure: () => dispatchState({ type: 'error' }),
                onSuccess: () => {
                    setTimeout(() => {
                        dispatchState({ type: 'logInAsSuccess' });
                    }, 1000);
                },
            })
        );
    };

    return state.success ? (
        <div className={authStyles.authWrapper}>
            <Grid
                container
                centered
                mobile={widths.mobile}
                tablet={widths.tablet}
                computer={widths.computer}
            >
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                        textAlign="center"
                    >
                        <h3>
                            {state.label}
                            <span role="img" aria-label="success" style={{ marginLeft: '1rem' }}>
                                🎉
                            </span>
                        </h3>
                        <p className="p1">
                            You are now Logged in as: <strong>{user.email}</strong>
                        </p>
                        <LinkWithArrow to="/account" label="Go to Account page" centered />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    ) : (
        <>
            <div className={authStyles.authWrapper}>
                <Grid
                    container
                    centered
                    mobile={widths.mobile}
                    tablet={widths.tablet}
                    computer={widths.computer}
                >
                    <Grid.Row>
                        <Grid.Column
                            mobile={widths.mobile}
                            tablet={widths.tablet}
                            computer={widths.computer}
                            textAlign="center"
                        >
                            <h3>Welcome, agent!</h3>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column
                            mobile={widths.mobile}
                            tablet={widths.tablet}
                            computer={widths.computer}
                            textAlign="center"
                        >
                            <p className="p1">
                                You are about to perform a <strong>Log in as</strong> admin action.
                                Please make sure to follow CX Team protocol and don't forget to
                                Logout when you are done.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <div className={authStyles.authWrapper}>
                <Grid className={authStyles.bottomContent} container centered>
                    <Grid.Row>
                        <Grid.Column
                            mobile={widths.mobile}
                            tablet={widths.tablet}
                            computer={widths.computer}
                            textAlign="center"
                        >
                            {state.loading ? (
                                <Loader>
                                    <h3>{state.label}</h3>
                                </Loader>
                            ) : (
                                <>
                                    <p className="p1">
                                        You will log in as: <strong>{user.email}</strong>
                                    </p>
                                    <Button
                                        onClick={handleOnLogInAsClick}
                                        fluid
                                        secondary
                                        disabled={state.loading}
                                    >
                                        Log In As
                                    </Button>
                                    {state.error && (
                                        <p className="p1" style={{ color: 'red' }}>
                                            {state.label}
                                        </p>
                                    )}
                                </>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </>
    );
};

export default LoginAs;
