import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import Loadder from 'src/components/shared/Loader';
import Unauthorized from 'src/components/log-in-as/Unauthorized';
import SomethingWentWrong from 'src/components/log-in-as/SomethingWentWrong';
import LogInAs from 'src/components/log-in-as/LogInAs';
import AlreadyLoggedIn from 'src/components/log-in-as/AlreadyLoggedIn';
import NotAnAdmin from 'src/components/log-in-as/NotAnAdmin';

import Selectors from 'src/state/root-selectors';

const LogInAsPage = () => {
    const [loading, setLoading] = useState(false);
    const { loggedIn, loadingTokenAttrs, cxAgent, agentId } = useSelector((state) => ({
        loggedIn: Selectors.auth.loggedIn(state),
        loadingTokenAttrs: Selectors.account.loadingTokenAttrs(state),
        cxAgent: Selectors.account.cx(state),
        agentId: Selectors.auth.userId(state),
    }));

    const query = queryString.parse(window.location.search);
    const userId = typeof query.userId === 'string' ? query.userId : '';
    const userEmail = typeof query.userEmail === 'string' ? query.userEmail : '';

    if (!loading) {
        if (!loggedIn) return <Unauthorized />;
        if (loadingTokenAttrs) return <Loadder />;
        if (!cxAgent) return <NotAnAdmin />;

        if (!userId || !userEmail) return <SomethingWentWrong />;
        if (userId === agentId) return <AlreadyLoggedIn userEmail={userEmail} />;
    }

    return <LogInAs user={{ id: userId, email: userEmail }} setLoading={setLoading} />;
};

export default LogInAsPage;
