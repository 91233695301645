import React from 'react';
import { Grid } from 'semantic-ui-react';
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';

import * as authStyles from 'src/styles/auth.module.scss';

const NotAnAdmin = () => {
    const { widths } = SUI_GRID_CENTERED;
    return (
        <div className={authStyles.authWrapper}>
            <Grid
                container
                centered
                mobile={widths.mobile}
                tablet={widths.tablet}
                computer={widths.computer}
            >
                <p className="p1">You must login to perform this action.</p>
                <Grid className={authStyles.bottomContent} container centered>
                    <Grid.Row>
                        <Grid.Column
                            mobile={widths.mobile}
                            tablet={widths.tablet}
                            computer={widths.computer}
                            textAlign="center"
                        >
                            <p className="p1">
                                You do not have permission to perform this action. Please contact an
                                admin to obtain permission.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid>
        </div>
    );
};

export default NotAnAdmin;
